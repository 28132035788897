import React from "react";
import Image from "next/image";

interface IMerchantTypeIconProps {
  imageUrl: string;
  imageAlt: string;
  label: string;
}

export default function MerchantTypeIcon({
  imageUrl,
  imageAlt,
  label,
}: IMerchantTypeIconProps) {
  return (
    <li className="col-6 col-lg-3 text-center">
      <div className="home__merchant-type-icons">
        <Image
          src={imageUrl}
          alt={imageAlt}
          width={158}
          height={158}
          layout="responsive"
        />
      </div>
      <p className="icon-label--lg">{label}</p>
    </li>
  );
}
