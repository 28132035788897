import React from "react";
import Image from "next/image";

import { ReactI18NextChild } from "react-i18next";

interface ITestimonialsProps {
  text: ReactI18NextChild;
  imageUrl: string;
  personName: string;
  companyName: string;
}

export default function Testimonials({
  text,
  imageUrl,
  personName,
  companyName,
}: ITestimonialsProps) {
  return (
    <li className="col-lg-4 testimonial">
      <p className="testimonial__text">{text}</p>
      <div className="row testimonial__person">
        <div className="testimonial__person__avatar-container">
          <Image
            src={imageUrl}
            alt={personName}
            className="testimonial__person__avatar"
            layout="fill"
          />
        </div>
        <div>
          <p className="testimonial__person__name">{personName}</p>
          <p className="testimonial__person__company">{companyName}</p>
        </div>
      </div>
    </li>
  );
}
