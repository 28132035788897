import React from 'react';

interface IMerchantLogoTypeObj {
  name: string;
  logoUrl: string;
  formUrl: string;
}

export default function FeaturedMerchants({ name, logoUrl, formUrl }: IMerchantLogoTypeObj){
  return (
    <li className="col-6 col-md-4 col-lg-2">
      <a href={`/${formUrl}`} target="_blank" rel="noreferrer">
        <img className="img-fluid home__merchant-logo" src={logoUrl} alt={`${name} logo`}/>
      </a>
    </li>
  );
}



