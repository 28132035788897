import React from 'react';

export default function Pricing(){
  return (
    <>
      <h2 className="home__subheader text-center">
        Pricing
      </h2>
      <div className="home__pricing-container">
        <p className="home__price-fee">2.1%</p>
        <p className="mb-0">+ <a href="https://stripe.com/pricing" target="_blank" rel="noreferrer">Stripe payment processing fees</a></p>
        <p><small className="text-subtle">(Typically 2.9% + $0.30 per transaction for US & Canada, but may vary.)</small></p>
        <p className="home__price-text">No monthly fee. Risk-free.</p>
      </div>
    </>
  );
}
