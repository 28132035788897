import React, { useState } from 'react';

const faqs = [
  {
    question: "Who is FormPay for?",
    answer: "FormPay is perfect for anyone looking to start selling online quickly and easily! Whether you're just starting out on your own, an independent business with employees, a tech startup, a school, or a community group, we support everyone. You can sell anything from homemade food, party trays, crafts, school uniforms, event tickets, fundraising items, sponsorships, or anything else. FormPay makes it a breeze to get up and running in no time."
  },
  {
    question: "What payment methods do you support?",
    answer: "We use <a rel='noopener noreferrer' href='https://stripe.com/' target='_blank'>Stripe</a> for payment processing, which means you can accept all the payment methods Stripe offers. This includes major credit cards like Visa, Mastercard, and American Express, as well as Cash App, Google Pay, Apple Pay, WeChat Pay, and Buy Now Pay Later options like Klarna, Affirm, and ClearPay, and much more. You can find more details on all of Stripe’s available payment methods <a rel='noopener noreferrer' href='https://stripe.com/payments/payment-methods' target='_blank'>here</a>. Plus, you get to choose which payment options to offer your customers."
  },
  {
    question: "How much does FormPay cost? Are there monthly fees?",
    answer: "FormPay charges just 2.1% per transaction. Plus, you'll also incur a <a rel='noopener noreferrer' href='https://stripe.com/pricing' target='_blank'>payment processing fee</a> from our payment processor Stripe, which typically ranges from 1% to 4% per transaction, depending on the country and payment methods you choose. For the US and Canada, Stripe's fees are usually 2.9% + $0.30 to accept the most payment types. The best part? There are no monthly or setup fees, making FormPay completely risk-free. Transaction fees are automatically deducted when the transaction is processed, so you don’t need to worry about giving us your credit card or any extra steps."
  },
  {
    question: "What currencies do you support?",
    answer: "We currently support dollar-based currencies, specifically USD, CAD, AUD, BBD, BND, BSD, BZD, FJD, HKD, JMD, NAD, NZD, SGD, and TTD."
  },
  {
    question: "What countries do you support?",
    answer: "We support any country that Stripe supports! For a full list of supported countries, please see <a rel='noopener noreferrer' href='https://stripe.com/en-ca/global' target='_blank'>here</a>. Please note that we only support dollar-based currencies at this time."
  },
  {
    question: "Do you support tax on transactions?",
    answer: "Absolutely! Just contact us at <a href='mailto:info@getformpay.com'>info@getformpay.com</a>, and we'll help you set up tax charges for your transactions."
  },
  {
    question: "How do I get notified that someone purchased something from me?",
    answer: "Both you and your customers will receive an email confirmation with all the order details and totals paid. Plus, you’ll get an order dashboard for each form where you can manage order and payment statuses."
  },
  {
    question: "How will I get paid?",
    answer: "FormPay's payment processor, Stripe, handles everything! There’s a payout period of 3 to 7 days, after which Stripe will deposit your earnings directly into your bank account. Learn more about <a rel='noopener noreferrer' href='https://support.stripe.com/questions/payout-schedules-faq' target='_blank'>Stripe payouts here</a>."
  },
  {
    question: "Can you help me get set up?",
    answer: "Yes! We can get you set up for free. Just book a time with us <a rel='noopener noreferrer' href='https://calendly.com/melodyma/15min' target='_blank'>here</a>."
  },
  {
    question: "More questions?",
    answer: "<a href='mailto:info@getformpay.com'>Contact us</a> and we’ll get back right away."
  }
];

export default function Faqs() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number | null) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <h2 className="home__subheader text-center">
        FAQs
      </h2>
     
      <div className="home__faq-container">
        {faqs.map((faq, index) => (
          <div className="home__faq-panel" key={index}>
            <div id={`heading${index}`}>
              <button
                className="btn btn-link btn-block text-left home__faq-heading"
                type="button"
                onClick={() => toggleAccordion(index)}
                aria-expanded={activeIndex === index}
                aria-controls={`collapse${index}`}
              >
                
                <h3 className="home__faq-heading-text">{faq.question}</h3>
                <i className={`home__faq-icon bi bi-${activeIndex === index ? 'dash' : 'plus'}`}></i>
              </button>
            </div>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
              aria-labelledby={`heading${index}`}
            >
              <p className="home__faq-text" dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
