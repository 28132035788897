import React from "react";

import Benefits from "./Benefits";
import Cta from "./Cta";
import Hero from "./Hero";
import MerchantTypeIcon from "./MerchantTypeIcon";
import TestimonialList from "./TestimonialList";
import Pricing from "./Pricing";
import Faqs from "./Faqs";
import FeaturedMerchant from "./FeaturedMerchant";

import { PageWrapper } from "../layout";
import LanderOgMetaData from "../openGraph/LanderOgMetaData";
import { useRouter } from "next/router";
import { BlogPosts } from "../../content/blogs";
import PostList from '../blog/PostList';

interface ILanderProps {
  obj: ILanderObj;
  videoSrc: string;
}

interface ILanderObj {
  header: string;
  subheader: string;
  merchantTypes: Array<IMerchantTypeObj>;
  benefits: Array<IBenefitsObj>;
  featuredMerchants?: Array<IMerchantLogoTypeObj>;
  successStories: Array<number>;
}

interface IMerchantTypeObj {
  imageUrl: string;
  imageAlt: string;
  label: string;
}

interface IBenefitsObj {
  imageUrl: string;
  imageAlt: string;
  colorClass: string;
  heading: string;
  description: string;
}

interface IMerchantLogoTypeObj {
  name: string;
  logoUrl: string;
  formUrl: string;
}


export default function Home({ obj, videoSrc }: ILanderProps) {
  const router = useRouter();
  const successStories = BlogPosts.filter(post => obj.successStories.includes(post.id));

  return (
    <>
      {router.pathname && <LanderOgMetaData urlSlug={router.pathname} />}
      <PageWrapper>
        <main className="home">
          <div className="container">
            <Hero heading={obj.header} gifUrl={videoSrc} gifAlt="Store" />
            <div className="mb-5 mt-5">
              <TestimonialList />
            </div>
            <hr className="divider--section" />
            {!!obj.featuredMerchants &&
              <>
                <div className="mb-5 mt-5">
                  <h2 className="home__subheader">Trusted by sellers everywhere</h2>
                  <ul className="list-unstyled list--horizontal row justify-content-center">
                    {obj.featuredMerchants.map((merchant, index) => {
                      return (
                        <FeaturedMerchant 
                          key={index}
                          name={merchant.name}
                          logoUrl={merchant.logoUrl}
                          formUrl={merchant.formUrl} />
                      )
                    })}
                  </ul>
                </div>
                <hr className="divider--section" />
              </>
            }
            <div>
              <h2 className="home__subheader text-center">{obj.subheader}</h2>
              <ul className="row list-unstyled">
                {obj.merchantTypes.map((type, index) => (
                  <MerchantTypeIcon
                    key={index}
                    imageUrl={type.imageUrl}
                    imageAlt={type.imageAlt}
                    label={type.label}
                  />
                ))}
              </ul>
            </div>
            <hr className="divider--section" />
            <div>
              <h2 className="home__subheader text-center">
                Everything you need to start selling quickly
              </h2>
              <ul className="list-unstyled container">
                {obj.benefits.map((benefit, index) => (
                  <Benefits
                    key={index}
                    isReverse={index % 2 == 1 ? true : false}
                    imageUrl={benefit.imageUrl}
                    imageAlt={benefit.imageAlt}
                    colorClass={benefit.colorClass}
                    heading={benefit.heading}
                    description={benefit.description}
                  />
                ))}
              </ul>
            </div>
            <hr className="divider--section" id="pricing" />
              <Pricing />
            <hr className="divider--section" />
              <Faqs />
            <hr className="divider--section" />
            <div className="container">
              <h2 className="home__subheader">Success Stories</h2>
              <PostList posts={successStories} withoutDate />
            </div>
            <hr className="divider--section" />
            <Cta classes="cta--emphasis" gtagLabel="home_bottom" />
          </div>
        </main>
      </PageWrapper>
    </>
  );
}
