import React, { Fragment } from 'react';

import Testimonials from './Testimonials';

export default function TestimonialList() {
  return (
    <Fragment>
      <h2 className="home__subheader">Why people love FormPay</h2>
      <ul className="row list-unstyled">
        <Testimonials 
          text={<Fragment>“I’m so glad we switched! Google Forms was super clumsy. <b>With FormPay, we get all the order info we need and we get paid instantly.</b>”</Fragment>} 
          imageUrl="https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/c2978a5e-0528-4998-9083-65db43c9df00/public" 
          personName="Danny M." 
          companyName="Podyssey"/>
        <Testimonials 
          text={<Fragment>“<b>FormPay has significantly reduced my admin workload</b>, freeing up about 2 to 3 hours per week.”</Fragment>} 
          imageUrl="https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/e00ba23b-1c6c-4ac0-da66-8dac9b6dd700/public" 
          personName="Guiliana T." 
          companyName="G Sweets"/>
        <Testimonials 
          text={<Fragment>“<b>I was immediately sold on FormPay when I heard I no longer have to text back and forth with clients for custom orders.</b> I can just send them a link to order!”</Fragment>} 
          imageUrl="https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/a04bc629-4e51-4700-2496-f7e54772bf00/public" 
          personName="Jenn X." 
          companyName="Good Bones"/>
      </ul>
    </Fragment>
  );
}
