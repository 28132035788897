import React from "react";
import Image from "next/image";

interface IBenefitsProps {
  isReverse?: boolean;
  imageUrl: string;
  imageAlt: string;
  colorClass: string;
  heading: string;
  description: string;
}

export default function Benefits({
  isReverse,
  imageUrl,
  imageAlt,
  colorClass,
  heading,
  description,
}: IBenefitsProps) {
  return (
    <li className="row home__benefit-section-row">
      <div
        className={`col-md-6 ${
          isReverse ? "home__benefit-section-row__reverse" : ""
        }`}
      >
        <div className="home__benefit-image-container">
          <Image
            src={imageUrl}
            alt={imageAlt}
            layout="fill"
            objectFit="contain"
          />
        </div>
      </div>
      <div className="col-md-6 home__benefit-section-textbox">
        <h6 className={`home__benefit-section-heading ${colorClass}`}>
          {heading}
        </h6>
        <p className="home__benefit-section-text">{description}</p>
      </div>
    </li>
  );
}
