import React from "react";

import SignUpButton from "./SignUpButton";

interface IHeroProps {
  heading: string;
  subheading?: string;
  gifUrl: string;
  gifAlt: string;
}

export default function Hero({
  heading,
  subheading,
  gifUrl,
  gifAlt,
}: IHeroProps) {
  return (
    <div className="row home__hero">
      <div className="col-lg-6">
        <h1 className="home__header">{heading}</h1>
        <p className="home__lead">
          {subheading ? (
            <b>{subheading}</b>
          ) : (
            <b>Create a beautiful online store with payments in minutes.</b>
          )}
        </p>
        <SignUpButton classes="btn-lg mt-2" />
      </div>
      <div className="col-lg-6 home__hero-video-container">
        <img className="home__hero-video" src={gifUrl} alt={gifAlt} />
      </div>
    </div>
  );
}
