export const HomeLanderContent = {
  header: "Easily sell anything online. Get set up in 5 minutes.",
  subheader: "Created with love for indie businesses",
  successStories: [17,16,39,41],
  featuredMerchants: [{
    name: "G Sweets",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/e00ba23b-1c6c-4ac0-da66-8dac9b6dd700/public",
    formUrl: "gsweets"
  },{
    name: "Ines Hats & Knits",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/08e878dc-63fd-4c85-d6f6-c9ca14c6aa00/public",
    formUrl: "ines"
  },{
    name: "Reframed Coaching",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/8bb88324-8bc0-40cf-9cf1-89715c602a00/public",
    formUrl: "reframed-coaching"
  },{
    name: "Uncle Tim's Filipino Delicacies",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/e09c1780-7598-40d9-0ad3-7dc526338000/public",
    formUrl: "uncletims"
  },{
    name: "Suelo & Faa",
    logoUrl: "https://www.getformpay.com/_next/image?url=https%3A%2F%2Fimagedelivery.net%2FINhRwDjwvBqjuxH-dRz9eQ%2F9a055cce-c6a1-4eb8-8025-b3b2f2c53b00%2Fpublic&w=1920&q=75",
    formUrl: "suelofaa"
  },{
    name: "KKLM Beauty",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/5db6c233-cf04-4dcd-03a3-2dc2a9548e00/public",
    formUrl: "kklm"
  }],
  merchantTypes: [{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/f6dd147d-f3c2-46cd-a41b-45bab0073100/public",
    imageAlt: "Icecream",
    label: "Food & Beverage"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/6126e8cd-8c51-4542-3efe-8e7701b74c00/public", 
    imageAlt: "Crafts",
    label: "Arts & Crafts"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/fec12864-de47-48c5-94d2-5163ccc15400/public", 
    imageAlt: "Tools", 
    label: "Personal Services"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/c4dac5f0-9f07-42a7-47b6-6e110a82de00/public",
    imageAlt: "Bar bells", 
    label: "Trainers & Coaches"
  }],
  benefits: [{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/03d8ce95-cc65-4276-2a29-855b438e0a00/public",
    imageAlt: "Drag and drop",
    colorClass: "u-pink",
    heading: "Easy, fast setup",
    description: "Create your perfect one-page store in minutes. Just drag and drop the form fields you need. No coding required. Starting an online store has never been easier."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/d2cdb32a-d729-4801-88b9-b0a344f1c900/public",
    imageAlt: "Select orders",
    colorClass: "u-purple",
    heading: "Accept orders with ease",
    description: "Whether you’re selling products or services, our powerful form tools will let your customers customize their orders exactly the way they want — with no additional work from you."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/710e3a3e-c7c7-4c90-0408-a2c5b7e1c000/public",
    imageAlt: "Easy link",
    colorClass: "u-lilac",
    heading: "Streamline sales with one link",
    description: "Eliminate back and forth order DMs! Keep track of who ordered and who paid. Simplify your sales process with a custom store link you can share on all your social bios."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/8ac04ac4-9262-4043-e5d2-6b4475590400/public",
    imageAlt: "Page builder",
    colorClass: "u-blue",
    heading: "Your store. Your brand.",
    description: "Personalize your store with your colors and logo. Your store will look good on mobile and desktop."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/41811b9e-7adf-4cce-5957-89350764aa00/public",
    imageAlt: "Pickup Delivery",
    colorClass: "u-green",
    heading: "Offer pickup or delivery",
    description: "Set pickup or delivery options based on your availability. Eliminate back and forth order DMs. Fulfilling orders will be a breeze!"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/1143ab6e-7c1d-458c-c4f3-1e1579586b00/public",
    imageAlt: "Accept tips",
    colorClass: "u-orange",
    heading: "Accept tips or donations",
    description: "Let your customers show their appreciation to you when they checkout."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/b0112f0d-774f-4a6c-8128-eda0b4852600/public",
    imageAlt: "Payment types",
    colorClass: "u-rose",
    heading: "Get paid the way you want",
    description: "Accept all types of payments — credit, debit, CashApp, and Google Pay, Apple Pay, WeChat Pay, Klarna, and more. No more payment hassles, and never turn a sale away again. Plus, we use Stripe for payment processing, so you know it's safe and secure."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/cfacd86e-bfed-481f-4faf-cacbf2ae6600/public",
    imageAlt: "Receipt",
    colorClass: "u-pink",
    heading: "Automatic order confirmations",
    description: "Both you and your buyer will receive automatic email order confirmations, so you'll always know when you get an order."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/c30442ac-7819-4f3e-61c1-8c5b5c3c5b00/public",
    imageAlt: "Mailing list",
    colorClass: "u-purple",
    heading: "A single place for orders and contacts",
    description: "Manage all your orders in one dashboard to stay on top of everything. Automatically collect customer emails and phone numbers at checkout to build your contact lists, so you can keep them coming back."
  }]
}

export const FoodLanderContent = {
  header: "Easily sell food online. Get set up in 5 minutes.",
  subheader: "Created with love for indie businesses",
  successStories: [16,17,34,32],
  featuredMerchants: [{
    name: "G Sweets",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/e00ba23b-1c6c-4ac0-da66-8dac9b6dd700/public",
    formUrl: "gsweets"
  },{
    name: "Marams Cakery",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/0df0dda6-9b5b-4bf2-dd80-e25bf9275a00/public",
    formUrl: "maramscakery"
  },{
    name: "Kam Wai Dim Sum",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/f4989eeb-94be-4c50-7ece-59ba2e0cda00/public",
    formUrl: "kamwaidimsum"
  },{
    name: "Uncle Tim's Filipino Delicacies",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/e09c1780-7598-40d9-0ad3-7dc526338000/public",
    formUrl: "uncletims"
  },{
    name: "Minas Brazilian Coffee Beans",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/a6bad27b-457e-4d2d-ad75-16469e731000/public",
    formUrl: "braziliancoffeebeans"
  },
  {
    name: "Miriam's",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/2d513459-653a-4758-d495-5e15283fbc00/public",
    formUrl: "miriams"
  }],
  merchantTypes: [{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/def17cdb-e8d9-497a-d27a-72e509c0f200/public",
    imageAlt: "Cake",
    label: "Cakes & Baked Goods"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/93105f89-5c4d-4640-7c4e-9a9d2bbd6800/public", 
    imageAlt: "Catering",
    label: "Catering"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/f6dd147d-f3c2-46cd-a41b-45bab0073100/public", 
    imageAlt: "Icecream", 
    label: "Packaged Foods"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/89fc5607-8ec9-449e-4cfa-0aee7099c500/public",
    imageAlt: "Beverage", 
    label: "Beverage & Mixers"
  }],
  benefits: [{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/03d8ce95-cc65-4276-2a29-855b438e0a00/public",
    imageAlt: "Drag and drop",
    colorClass: "u-pink",
    heading: "Easy, fast setup",
    description: "Create your perfect one-page store in minutes. Just drag and drop the form fields you need. No coding required. Starting an online store has never been easier."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/d2cdb32a-d729-4801-88b9-b0a344f1c900/public",
    imageAlt: "Select orders",
    colorClass: "u-purple",
    heading: "Accept orders with ease",
    description: "Whether you’re selling made-to-order cakes or catering an event, our powerful form tools will let your customers customize their orders exactly the way they want — with no additional work from you."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/8ac04ac4-9262-4043-e5d2-6b4475590400/public",
    imageAlt: "Page builder",
    colorClass: "u-lilac",
    heading: "Your store. Your brand.",
    description: "Personalize your store with your colors and logo. Your store will look good on mobile and desktop."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/41811b9e-7adf-4cce-5957-89350764aa00/public",
    imageAlt: "Pickup Delivery",
    colorClass: "u-green",
    heading: "Offer pickup or delivery",
    description: "Set pickup or delivery options based on your availability. Eliminate back and forth order DMs. Fulfilling orders will be a breeze!"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/b0112f0d-774f-4a6c-8128-eda0b4852600/public",
    imageAlt: "Payment types",
    colorClass: "u-blue",
    heading: "Get paid the way you want",
    description: "Accept all types of payments — credit, debit, CashApp, Google Pay, Apple Pay, WeChat Pay, Klarna, and more. No more payment hassles, and never turn a sale away again. Plus, we use Stripe for payment processing, so you know it's safe and secure."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/1143ab6e-7c1d-458c-c4f3-1e1579586b00/public",
    imageAlt: "Accept tips",
    colorClass: "u-orange",
    heading: "Accept tips",
    description: "Let your customers show their appreciation to you when they checkout."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/cfacd86e-bfed-481f-4faf-cacbf2ae6600/public",
    imageAlt: "Receipt",
    colorClass: "u-pink",
    heading: "Automatic order confirmations",
    description: "Both you and your buyer will receive automatic email order confirmations, so you'll always know when you get an order."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/c30442ac-7819-4f3e-61c1-8c5b5c3c5b00/public",
    imageAlt: "Mailing list",
    colorClass: "u-purple",
    heading: "A single place for orders and contacts",
    description: "Manage all your orders in one dashboard to stay on top of everything. Automatically collect customer emails and phone numbers at checkout to build your contact lists, so you can keep them coming back."
  }]
}

export const ArtLanderContent = {
  header: "Easily sell art online. Get set up in 5 minutes.",
  subheader: "Created with love for indie creatives",
  successStories: [39,43,32,34],
  featuredMerchants: [{
    name: "Ines Hats & Knits",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/08e878dc-63fd-4c85-d6f6-c9ca14c6aa00/public",
    formUrl: "ines"
  },{
    name: "Good Bones Ceramics",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/3510f47a-567a-4779-0159-1b383889f100/public",
    formUrl: "goodbones"
  },{
    name: "KKLM Beauty",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/5db6c233-cf04-4dcd-03a3-2dc2a9548e00/public",
    formUrl: "kklm"
  },{
    name: "Chinatown Today",
    logoUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/02ae3ca9-f0fc-4c75-2493-121d813d5600/public",
    formUrl: "chinatowntoday"
  }],
  merchantTypes: [{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/31f921f8-fc7e-446a-bcab-cc7c634e2300/public",
    imageAlt: "Art",
    label: "Visual Arts & Illustrations"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/1d0d6388-37e1-4fc9-4f11-7982d7e4b700/public", 
    imageAlt: "Ceramics",
    label: "Ceramics"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/6126e8cd-8c51-4542-3efe-8e7701b74c00/public", 
    imageAlt: "Crafts", 
    label: "Handmade Crafts"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/ae3a9544-2e4c-476b-bb60-7cfdd9de9e00/public",
    imageAlt: "Jewelry", 
    label: "Jewelry"
  }],
  benefits: [{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/ef77ba0f-12d4-40ea-3012-24f2b8480900/public",
    imageAlt: "Drag and drop",
    colorClass: "u-pink",
    heading: "Easy, fast setup",
    description: "Create your perfect one-page store in minutes. Just drag and drop the form fields you need. No coding required. Starting an online store has never been easier."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/80edc578-f15c-43c7-0ac5-86b168665500/public",
    imageAlt: "Select orders",
    colorClass: "u-purple",
    heading: "Accept orders with ease",
    description: "Whether you’re selling digital or physical work, our powerful form tools will let your customers customize their commissions exactly the way they want. No more back and forth DMs!"
  },
  // {
  //   imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/a437616c-cfab-4c12-fd96-94642dbdcf00/public",
  //   imageAlt: "Image uploader",
  //   colorClass: "u-lilac",
  //   heading: "Built-in image uploads",
  //   description: "Your customers can upload inspirational photos and images directly to the order in one go."
  // },
  {
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/8ac04ac4-9262-4043-e5d2-6b4475590400/public",
    imageAlt: "Page builder",
    colorClass: "u-green",
    heading: "Your store. Your brand.",
    description: "Personalize your store with your colors and logo. Your store will look good on mobile and desktop."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/41811b9e-7adf-4cce-5957-89350764aa00/public",
    imageAlt: "Pickup Delivery",
    colorClass: "u-blue",
    heading: "Offer pickup or delivery",
    description: "Set pickup or delivery options based on your availability. Eliminate back and forth order DMs. Fulfilling orders will be a breeze!"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/b0112f0d-774f-4a6c-8128-eda0b4852600/public",
    imageAlt: "Payment types",
    colorClass: "u-orange",
    heading: "Get paid the way you want",
    description: "Accept all types of payments — credit, debit, CashApp, Google Pay, Apple Pay, WeChat Pay, Klarna, and more. No more payment hassles, and never turn a sale away again. Plus, we use Stripe for payment processing, so you know it's safe and secure."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/cfacd86e-bfed-481f-4faf-cacbf2ae6600/public",
    imageAlt: "Receipt",
    colorClass: "u-pink",
    heading: "Automatic order confirmations",
    description: "Both you and your buyer will receive automatic email order confirmations, so you'll always know when you get an order."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/c30442ac-7819-4f3e-61c1-8c5b5c3c5b00/public",
    imageAlt: "Mailing list",
    colorClass: "u-purple",
    heading: "A single place for orders and contacts",
    description: "Manage all your orders in one dashboard to stay on top of everything. Automatically collect customer emails and phone numbers at checkout to build your contact lists, so you can keep them coming back."
  }]
}

export const RegistrationLanderContent = {
  header: "Easily sell registrations online. Get set up in 5 minutes.",
  subheader: "Created with love for indie organizations and creators",
  successStories: [33,41,32,43],
  merchantTypes: [{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/30742e36-8c97-4d0a-fdc5-94fa71a9ba00/public",
    imageAlt: "Ball",
    label: "Clubs & Teams"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/af35d50d-e6a8-4050-c86d-aabf6058f700/public", 
    imageAlt: "Community Groups",
    label: "Community Groups"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/83953375-f6b3-4221-3341-897660d9cc00/public", 
    imageAlt: "Tickets", 
    label: "Events & Conferences"
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/fec12864-de47-48c5-94d2-5163ccc15400/public",
    imageAlt: "Tools", 
    label: "Class Creators & Tutors"
  }],
  benefits: [{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/97751676-7940-41b5-3b63-6a7e0916b700/public",
    imageAlt: "Drag and drop",
    colorClass: "u-pink",
    heading: "Easy, fast setup",
    description: "Create your perfect one-page registration page in minutes. Just drag and drop the form fields you need. No coding required. Selling registrations and memberships online has never been easier."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/3cb3e083-9b3c-450c-b9d2-b7b51c9a9300/public",
    imageAlt: "Select orders",
    colorClass: "u-purple",
    heading: "Accept orders with ease",
    description: "Whether you need to know someone’s t-shirt size for a sports team or dietary restrictions for a summer camp, our powerful form tools will let you gather all the info you need to know about your members."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/8ac04ac4-9262-4043-e5d2-6b4475590400/public",
    imageAlt: "Page builder",
    colorClass: "u-lilac",
    heading: "Your store. Your brand.",
    description: "Personalize your store with your colors and logo. Your store will look good on mobile and desktop."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/1143ab6e-7c1d-458c-c4f3-1e1579586b00/public",
    imageAlt: "Accept donations",
    colorClass: "u-orange",
    heading: "Accept donations",
    description: "Let your members show their appreciation for you when they checkout."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/b0112f0d-774f-4a6c-8128-eda0b4852600/public",
    imageAlt: "Payment types",
    colorClass: "u-blue",
    heading: "Get paid the way you want",
    description: "Accept all types of payments — credit, debit, CashApp, Google Pay, Apple Pay, WeChat Pay, Klarna, and more. No more payment hassles, and never turn a sale away again. Plus, we use Stripe for payment processing, so you know it's safe and secure."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/cfacd86e-bfed-481f-4faf-cacbf2ae6600/public",
    imageAlt: "Receipt",
    colorClass: "u-pink",
    heading: "Automatic order confirmations",
    description: "Both you and your buyer will receive automatic email order confirmations, so you'll always know when you get a registration."
  },{
    imageUrl: "https://imagedelivery.net/INhRwDjwvBqjuxH-dRz9eQ/c30442ac-7819-4f3e-61c1-8c5b5c3c5b00/public",
    imageAlt: "Mailing list",
    colorClass: "u-purple",
    heading: "A single place for orders and contacts",
    description: "Manage all your orders in one dashboard to stay on top of everything. Automatically collect customer emails and phone numbers at checkout to build your contact lists, so you can keep them coming back."
  }]
}